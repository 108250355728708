import { helper as buildHelper } from '@ember/component/helper';
import { assert } from '@ember/debug';

let undefinedOrBoolean = function (value) {
  return typeof value === 'undefined' || typeof value === 'boolean';
};

let ACCEPTABLE_TYPES = [
  'primary',
  'secondary',
  'tertiary',
  'primary-destructive',
  'secondary-destructive',
  'primary-live',
  'secondary-live',
  'primary-paywall',
  'secondary-paywall',
  'secondary-floating',
];
let acceptedSizes = ['normal', 'small', 'large'];
let acceptedCellButtonTypes = ['secondary', 'secondary-destructive'];

let buttonClassesMetal = function (options) {
  let classes = ['btn'];
  let type = ACCEPTABLE_TYPES.indexOf(options.type) === -1 ? 'secondary' : options.type;
  let size = acceptedSizes.indexOf(options.size) === -1 ? 'normal' : options.size;

  assert(
    'A button cannot be in both a right and left list',
    !(options.inRightList && options.inLeftList),
  );
  assert(
    'Only the the button types primary, secondary, and secondary-destructive can be table cell buttons',
    !options.inCell || acceptedCellButtonTypes.indexOf(type) !== -1,
  );
  assert(
    'showsIconOnly must be a boolean value if specified',
    undefinedOrBoolean(options.showsIconOnly),
  );
  assert('inCell must be a boolean value if specified', undefinedOrBoolean(options.inCell));
  assert('fit must be a boolean value if specified', undefinedOrBoolean(options.fit));
  assert(
    'inRightList must be a boolean value if specified',
    undefinedOrBoolean(options.inRightList),
  );
  assert('inLeftList must be a boolean value if specified', undefinedOrBoolean(options.inLeftList));
  assert('isDisabled must be a boolean value if specified', undefinedOrBoolean(options.isDisabled));
  assert('isSaving must be a boolean value if specified', undefinedOrBoolean(options.isSaving));
  assert(
    'isForcedActive must be a boolean value if specified',
    undefinedOrBoolean(options.isForcedActive),
  );
  assert('isSubtle must be a boolean value if specified', undefinedOrBoolean(options.isSubtle));
  assert('Cell buttons cannot be marked as subtle', !(options.isSubtle && options.inCell));
  assert(
    'isTruncated must be a boolean value if specified',
    undefinedOrBoolean(options.isTruncated),
  );
  assert('hasError must be a boolean value if specified', undefinedOrBoolean(options.hasError));
  assert(
    'hasError can only be applied to dropdown openers',
    !options.hasError || options.isDropdownOpener,
  );

  classes.push(`o__${options.inCell ? 'cell-' : ''}${type}`);
  if (options.showsIconOnly) {
    classes.push('o__icon');
  }
  if (options.fit || options.isTruncated) {
    classes.push('o__fit');
  }
  if (options.inRightList) {
    classes.push('o__in-right-list');
  }
  if (options.inLeftList) {
    classes.push('o__in-left-list');
  }
  if (size === 'small') {
    classes.push('o__small');
  } else if (size === 'large') {
    classes.push('o__large');
  }
  if (options.isSaving) {
    classes.push('o__saving', 'o__disabled');
  } else if (options.isDisabled) {
    classes.push('o__disabled');
  }
  if (options.isForcedActive) {
    classes.push('o__forced-active');
  }
  if (options.isSubtle) {
    classes.push('o__subtle');
  }
  if (options.isDropdownOpener) {
    classes.push('o__dropdown-opener');
    if (options.hasError) {
      classes.push('o__has-error');
    }
  }
  return classes;
};

function buttonClassesMetalHelper(params, options) {
  if (options === undefined) {
    options = {};
  }
  return buttonClassesMetal(options).join(' ');
}

export { ACCEPTABLE_TYPES, buttonClassesMetal, buttonClassesMetalHelper };

export default buildHelper(buttonClassesMetalHelper);
